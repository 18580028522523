import {
    ImageComponent as SourceImage,
} from 'SourceComponent/Image/Image.component';
import {
    ImageState,
} from 'SourceComponent/Image/Image.config';

/** @namespace Theme/Component/Image/Component */
export class ImageComponent extends SourceImage {
    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
        } = this.props;
        const { imageStatus } = this.state;

        return (
            <img
              block="Image"
              elem="Image"
              src={ src || '' }
              alt={ alt }
              mods={ { isLoading: imageStatus === ImageState.IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="eager"
            />
        );
    }

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
        } = this.props;

        return (
            <img
              block={ className }
              src={ src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="eager"
            />
        );
    }
}

export default ImageComponent;
