import {
    InstallPromptComponent as SourceInstallPrompt,
} from 'SourceComponent/InstallPrompt/InstallPrompt.component';

import './InstallPrompt.style';

/** @namespace Theme/Component/InstallPrompt/Component */
export class InstallPromptComponent extends SourceInstallPrompt {
    render() {
        return null;
    }
}

export default InstallPromptComponent;
